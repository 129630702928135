import React from "react";
import { Link } from "gatsby";
import '../styles/card.scss';
import { GatsbyImage, getImageData } from "gatsby-plugin-image";


export default function Card({ title, description, link, linkText, image }) {

  function getExampleImageData({ image, ...props }) {
    console.log(image)
    return getImageData({
      baseUrl: image.url,
      sourceWidth: image.width,
      sourceHeight: image.height,
      urlBuilder,
      pluginName: "gatsby-source-example",
      // If your host supports auto-format/content negotiation, pass this as the formats array
      formats: ["auto"],
      ...props,
    })
  }


  return (
    <Link to={link}>  <div className="card">

      <GatsbyImage
        key={title}
        image={image.childImageSharp.gatsbyImageData}
        placeholder="blurred"
        className="logo"
        style={{ borderRadius: '6px' }}
        formats={["auto", "webp", "avif"]}
        alt={`${title}`}
        transformOptions={{ fit: "cover", cropFocus: "attention" }}
      />

      <div className="card-content">
        <h3 className="fs-third-heading fw-bold">
          {title}
        </h3>

        {/* <Link to="/">
          <button className="button">{linkText}</button>
        </Link>  */}
      </div>

    </div>
    </Link>
  )
}
