import React from "react";
import { Link } from "gatsby";

export default function ImageInfo({ title, slug, date, onSlideChange, index, isActive, excerpt }) {
  const formattedDate = new Date(date).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })
  return (
    <div className={`image-info ${isActive ? 'currentImage' : ''}`}>
      <div className="content">
        <div className="text-info">
          <h2 className="fs-primary-heading">{title}</h2>
          <p>{excerpt}</p>
          <div className="bottom">


          </div>
        </div>
      </div>

    </div>
  )
}









