// Testimonials.js

import React from 'react';
import '../styles/testimonials.scss';
import AnimateIn from '../utilites/AnimateIn';
import { StaticImage } from "gatsby-plugin-image"

const Testimonials = () => {
  const testimonialsData = [
    {
      name: 'Leslie Codd',
      designation: 'Managing Director, Codd Property Holdings Ltd',
      image: 'CODD.png',
      testimonial:
        'We are extremely happy with the quality of build in this development. BHA had proven to be excellent builders with a very high level of professionalism both with their client and our end user. We have since commissioned BHA to build a second site in Kilkenny that will be starting construction in mid 2023. We would have no hesitation in recommending this company for any similar building projects throughout Ireland.',
    },
    {
      name: 'Tiejun Hui',
      designation: 'Tiejun Hui, Director SIEC Group Ltd',
      testimonial:
        'BHA have provided excellent service to SIEC Group Ltd over numerous projects around Ireland. The quality of their team inevitably leads to an excellent quality of work on all metrics. We continue to work with them for our ongoing and future projects and cannot recommend them highly enough.',
    },
    {
      name: 'Peter Kinsella',
      designation: 'Development Coordinator, Cooperative Housing Ireland ',
      testimonial:
        'CHI worked with BHA Construction on the delivery of 36 Residential Units at Dunamaise View, Portlaoise, Co. Laois. I found BHA to be a thoroughly professional organisation with approachable and competent staff at every level. The same competence was evident with their management of Health & Safety and their sub-contractors. Senior Management employ a hands-on approach. I have no hesitation in recommending BHA Construction as Main Constructors for similar works and I would welcome the opportunity to work with them again.',
    }
    // Add more testimonials here
  ];

  return (

    <section className="testimonials">
      <AnimateIn>
        <h2 className="testimonials__heading fs-secondary-heading">What Our Clients Say</h2>
        <div className="testimonials__list">

          <div className="testimonial">
            <div className="testimonial__image">
              <StaticImage
                src={`../images/CODD.png`}
                placeholder="blurred"
                className="logo"
                formats={["auto", "webp", "avif"]}
                alt="BHA Logo"
                transformOptions={{ fit: "cover", cropFocus: "attention" }}

              />
            </div>
            <div className="testimonial__content">{testimonialsData[0].testimonial}</div>
            <div className="testimonial__author">
              <h3 className="testimonial__author-name">{testimonialsData[0].name}</h3>
              <p className="testimonial__author-designation">{testimonialsData[0].designation}</p>
            </div>
          </div>

          <div className="testimonial" >
            <div className="testimonial__image">
              <StaticImage
                src={`../images/siec.png`}
                placeholder="blurred"
                className="logo"
                formats={["auto", "webp", "avif"]}
                alt="BHA Logo"
                transformOptions={{ fit: "cover", cropFocus: "attention" }}
              />
            </div>
            <div className="testimonial__content">{testimonialsData[1].testimonial}</div>
            <div className="testimonial__author">
              <h3 className="testimonial__author-name">{testimonialsData[1].name}</h3>
              <p className="testimonial__author-designation">{testimonialsData[1].designation}</p>
            </div>
          </div>


          <div className="testimonial" >
            <div className="testimonial__image">
              <StaticImage
                src={`../images/cooperative housing ireland.svg`}
                placeholder="blurred"
                className="logo"
                formats={["auto", "webp", "avif"]}
                alt="BHA Logo"
                transformOptions={{ fit: "cover", cropFocus: "attention" }}
              />
            </div>
            <div className="testimonial__content">{testimonialsData[2].testimonial}</div>
            <div className="testimonial__author">
              <h3 className="testimonial__author-name">{testimonialsData[2].name}</h3>
              <p className="testimonial__author-designation">{testimonialsData[2].designation}</p>
            </div>
          </div>
        </div>
      </AnimateIn>
    </section>

  );
};

export default Testimonials;
