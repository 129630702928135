import React from "react";
import { Link } from "gatsby";
import '../styles/info-image.scss';
import { GatsbyImage, getImageData } from "gatsby-plugin-image";


export default function InfoImage({ title, description, link, linkText, image }) {
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 750;

  return (
    <Link>
      <div className="info-image">
        <GatsbyImage
          key={title}
          image={image.childImageSharp.gatsbyImageData}
          placeholder="blurred"
          className="logo"
          style={{ marginRight: isMobile ? '0' : '2rem' }}
          formats={["auto", "webp", "avif"]}
          alt={`${title}`}
          transformOptions={{ fit: "cover", cropFocus: "attention" }}
        />

        <div className="card-content">
          <h3 className="fs-third-heading fw-bold">

          </h3>
        </div>
      </div>
    </Link>
  )
}
