import React, { useState } from "react";
import ImageInfo from "./imageinfo";
import '../styles/main-image.scss';
import { GatsbyImage } from "gatsby-plugin-image";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useStaticQuery, graphql } from "gatsby"

export default function MainImage({ posts, onImageChange }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const data = useStaticQuery(graphql`
  query MyQuery {
        fireside: file(relativePath: { eq: "fireside.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1000)
          }
        },
        bathroom: file(relativePath: { eq: "bathroom.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1000)
          }
        },
        gym: file(relativePath: { eq: "gym.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1000)
          }
        },
        house: file(relativePath: { eq: "drone-1.JPG" }) {
          childImageSharp {
            gatsbyImageData(width: 1000)
          }
        }
  }
`)


  const handleNextImage = () => {
    if (currentImageIndex <= posts.length) {
      setCurrentImageIndex(currentImageIndex + 1);
    }

  };

  const handlePreviousImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const handleSlideChange = (direction) => {
    if (direction === 'next') {
      handleNextImage();
      return
    }

    handlePreviousImage();
  }
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 700;

  return (
    <>
      {/* <h1 style={{ visibility: 'hidden' }}>BHA, building a new generation</h1> */}

      {isMobile && <div className='video-player'>
        <video className='video-bg' poster={data['house'].childImageSharp.gatsbyImageData.images.fallback.src} preload="auto" autoPlay playsinline playsInline muted loop>
          <source src={"https://zoner.b-cdn.net/bha-drone-1.mp4"} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      }
      <div className="main-image-wrapper">
        <Carousel
          showThumbs={false}
          onChange={(index) => setCurrentImageIndex(index)}
          showArrows={false}
          showIndicators={false}
          showStatus={false}
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
          animationHandler={isMobile ? 'slide' : 'fade'}
          renderThumbs={() => null}
          //infiniteLoop={true}
          selectedItem={currentImageIndex}
        >

          {posts.map((p, i) => {

            const { title, coverImage, date, author, slug } = p;
            const { large: fluid } = coverImage;
            return <>
              <div className={`carousel-controls ${i === 0 ? 'isVideo' : ''}`}>
                <a onClick={() => handleSlideChange("previous")} >
                  <button className="button-secondary">⟵</button>
                </a>
                <a onClick={() => handleSlideChange("next")} >
                  <button className="button-secondary">⟶</button>
                </a>
              </div>
              {(i === 0 && !isMobile) ? <div className='video-player'>
                <video poster={data['house'].childImageSharp.gatsbyImageData.images.fallback.src} className='video-bg' autoPlay preload="auto" muted playsInline loop controls>
                  <source src={"https://zoner.b-cdn.net/bha-drone-1.mp4"} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div> :
                <GatsbyImage
                  image={fluid}
                  alt={`Cover Image for ${title}`}
                  className="main-image"
                  placeholder="blurred"
                  transformOptions={{ fit: "cover", cropFocus: "attention" }}
                />
              }
              <ImageInfo {...p} index={i} isActive={currentImageIndex === i} onSlideChange={handleSlideChange} />
            </>

          })}
        </Carousel>

        {/* <NewsHeader /> */}

      </div>
      {/* <NewsThumbnails currentImageIndex={currentImageIndex} posts={posts} /> */}
    </>
  )
}









