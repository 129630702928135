import React, { useState } from "react";
import '../styles/info-slide.scss';
import InfoImage from "./info-image";
import { useStaticQuery, graphql } from "gatsby"
import AnimateIn from "../utilites/AnimateIn";
import { Carousel } from 'react-responsive-carousel'

const cards = [{
  title: 'Fit Out',
  linkText: 'Learn more',
  image: 'hydefront',
  primary: true,
  description: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
},
{
  title: 'Residential',
  linkText: 'Learn more',
  image: 'ohanabar',
  secondary: true,
  description: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
},
{
  title: 'Commercial',
  linkText: 'Learn more',
  image: 'hydereception',
  primary: true,
  description: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
},
{
  title: 'Fire Protection',
  linkText: 'Learn more',
  image: 'hydeview',
  secondary: true,
  description: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
}
]
export default function InfoSlide() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const handleNextImage = () => {
    if (currentImageIndex <= cards.length) {
      setCurrentImageIndex(currentImageIndex + 1);
    }

  };

  const handlePreviousImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };
  const handleSlideChange = (direction) => {
    if (direction === 'next') {
      handleNextImage();
      return
    }

    handlePreviousImage();
  }
  const data = useStaticQuery(graphql`
    query InfoSlideQuery {
          hydefront: file(relativePath: { eq: "hyde-front.jpg" }) {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          },
          ohanabar: file(relativePath: { eq: "ohana-bar.jpg" }) {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          },
          hydereception: file(relativePath: { eq: "hyde-reception.jpg" }) {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          },
          hydeview: file(relativePath: { eq: "hyde-view.jpg" }) {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
    }
  `)
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 750;

  let extraProps = {
    centerMode: true,
    centerSlidePercentage: 40
  }

  if (isMobile) {
    extraProps = {
      centerMode: false,
      centerSlidePercentage: 100
    }
  }



  return (
    <section className="info-slide">
      <AnimateIn>
        <div className="info-slide-header">
          {/* <h2 className="fs-secondary-heading">Fit for Purpose</h2> */}
          <div className="carousel-controls">
            <a onClick={() => handleSlideChange("previous")} >
              <button className="button-secondary">⟵</button>
            </a>
            <a onClick={() => handleSlideChange("next")} >
              <button className="button-secondary">⟶</button>
            </a>
          </div>
        </div>

        <div className="info-sections">

          <Carousel
            showThumbs={false}
            autoPlay
            selectedItem={currentImageIndex}
            infiniteLoop
            showArrows={false}
            showIndicators={false}
            showStatus={false}
            // statusFormatter={(currentItem, total) => <>
            //   <span className="currentItem">0{currentItem}</span>/<span>0{total}</span>
            // </>}
            {...extraProps}
          >

            {cards.map((c, i) => {
              return <InfoImage {...c} image={data[c.image]} />
            }
            )}
          </Carousel>
        </div>
      </AnimateIn>
    </section>
  )

}
