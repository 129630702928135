import React from "react";
import Container from "../components/container";
import HeroPost from "../components/hero-post";
import MoreStories from "../components/more-stories";
import Header from "../components/header";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { graphql } from "gatsby";
import Main from "../components/main";
import FixturesWrapper from "../components/fixtures-wrapper";
import Testimonials from "../components/testimonials";
import EmailCollectorForm from "../components/email-collect";
import MenuContextProvider from "../context/menuContext";

export default function Index({ data: { allPosts, site, blog } }) {
  const heroPost = allPosts.nodes[0];
  const morePosts = allPosts.nodes.slice(1);

  return (
    <MenuContextProvider>
      <Container>
        <HelmetDatoCms seo={blog.seo} favicon={site.favicon} />
        <Header />
        <EmailCollectorForm />
        <Main posts={allPosts.nodes}></Main>
      </Container>
    </MenuContextProvider>
  );
}

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    blog: datoCmsBlog {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allProjects:   allDatoCmsProject {
    edges {
      node {
        descriptionText 
      }
    }
  }

    allPosts: allDatoCmsPost(sort: { fields: date, order: DESC }, limit: 20) {
      nodes {
        title
      
        excerpt
        date
     
        coverImage {
          large: gatsbyImageData(width: 1500)
          small: gatsbyImageData(width: 760)
        }

      }
    }
  }
`;
