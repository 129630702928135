import React, { useState, useEffect, useContext } from 'react';
import { MyContext } from "../context/menuContext";
import '../styles/email.scss';

const EmailCollectorForm = () => {
  const [email, setEmail] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const { isEmailFormOpen, updateEmailFormOpen } = useContext(MyContext);

  useEffect(() => {
    // const timeoutId = setTimeout(() => {
    //   // Add your pop-up code here
    //   // For example, you can update the state to show a pop-up component
    //   // or trigger a function to display a modal
    //   const isSubscribed = localStorage.getItem('subscribed');
    //   if (isSubscribed === 'true') {
    //     setShowDropdown(true);
    //   }
    //   updateEmailFormOpen(true);

    // }, 30000); // 30 seconds in milliseconds

    // Cleanup the timeout if the component unmounts before it expires
    // return () => clearTimeout(timeoutId);
  }, []);

  const handleClose = () => {
    updateEmailFormOpen(false);
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email === '*@gmail.com') {
      setShowDropdown(true);
      return;
    }

    try {
      const response = await fetch('/.netlify/functions/mailchimp', {
        method: 'POST',
        body: JSON.stringify({ email, audienceId: '7d0a3fe14a' }),
      });
      console.log(response);

      // Clear the email field after successful submission
      setEmail('');

      setShowDropdown(true);
      localStorage.setItem('subscribed', 'true');
    } catch (error) {
      console.error('Error subscribing:', error);
    }
  };

  const startDownload = (type) => {
    // Logic to start the download based on the selected type
    // Replace with your actual download implementation
    switch (type) {
      case 'pdf1':
        {
          const pdfResidential = 'https://drive.google.com/uc?export=download&id=1Aty39lcv1foRjrxO2kIRt1XV7KKKxShn';
          window.open(pdfResidential, '_blank');
        }
        break;
      case 'pdf2': {
        let pdfFitout = 'https://drive.google.com/uc?export=download&id=1Gpc7beIPWIEIfgcJzNqyCHwmapdV5SIY';
        window.open(pdfFitout, '_blank');
      }
        break;
      default:
        break;
    }
    updateEmailFormOpen(false);
  };



  return (
    <div className='email-collect'>
      {isEmailFormOpen && (
        <div className="popup-overlay">
          <div className="popup">
            <button className="close-button" onClick={handleClose}>X</button>
            <h2>Connect with us to download the brochure.</h2>
            <form onSubmit={handleSubmit}>

              {showDropdown ? (
                <><select
                  className="type-select"
                  value={selectedType}
                  onChange={handleTypeChange}
                  required
                >
                  <option value="">Choose Brochure</option>
                  <option value="pdf1">Residential</option>
                  <option value="pdf2">FitOut</option>
                </select>  <button onClick={() => startDownload(selectedType)} className="subscribe-button" >Download</button></>) : <><input
                  className="email-input"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                /> <button className="subscribe-button" type="submit">Download now</button></>}

            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EmailCollectorForm;
