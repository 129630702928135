import React from "react";
import MainImage from "./main-image";
import '../styles/banner.scss';

export default function Banner({ posts }) {
  return (
    <section className="banner">
      <MainImage posts={posts} />
    </section>
  )
}
