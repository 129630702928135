import React from "react";
import '../styles/info.scss';
import Card from "./card";
import { useStaticQuery, graphql } from "gatsby"
import { getImageData } from 'gatsby-plugin-image';
import AnimateIn from "../utilites/AnimateIn";

const cards = [{
  title: 'Hotel and Leisure Projects',
  linkText: 'Learn more',
  image: 'fireside',
  link: 'project-categories/hotel-and-leisure-projects-bha',
  primary: true,
  description: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
},
{
  title: 'Residential Projects',
  linkText: 'Learn more',
  link: 'project-categories/projects-residential-bha',
  image: 'bathroom',
  secondary: true,
  description: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
},
{
  title: 'Commercial, Retail and Fit-Out Projects',
  linkText: 'project-categories/projects-commercial-retail-and-fitout-bha',
  link: 'project-categories/projects-commercial-retail-and-fitout-bha',
  image: 'gym',
  primary: true,
  description: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
},
{
  title: 'Fire Protection Works and Upgrades Projects',
  linkText: 'project-categories/fire-protection-works-and-upgrade-projects-bha',
  image: 'house',
  link: 'project-categories/fire-protection-works-and-upgrade-projects-bha',
  secondary: true,
  description: '"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
}
]
export default function Info() {
  const data = useStaticQuery(graphql`
    query MyQuery {
          fireside: file(relativePath: { eq: "fireside.jpg" }) {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          },
          bathroom: file(relativePath: { eq: "bathroom.jpg" }) {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          },
          gym: file(relativePath: { eq: "gym.jpg" }) {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          },
          house: file(relativePath: { eq: "drone-1.JPG" }) {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
    }
  `)

  return (<section className="info">

    <h2 className="fs-secondary-heading">What we offer</h2>
    <div className="info-sections">
      {cards.map(c => <Card {...c} image={data[c.image]} />)}
    </div>

  </section>
  )

}
